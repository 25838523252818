<template>
    <div class="fullPage" @mousemove="mouseMove">
        <div class="banner">
            <h2>T
                <div class="circle">
                    <span id="circleMove">
                        <span class="eye"></span>
                    </span>
                </div>M
            </h2>
            <marquee class="marquee">
                <span>Frontend developer</span>
                <!-- <span>Vuejs</span> -->
                <span>Animation</span>
            </marquee>
            <h2>TRAN</h2>
        </div>
        <div class="sectionMenu">
            <div class="iconMenu" @click="toggleMenu" :class="flgMenu ? 'active' : 'not-active'">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="boxContent" :class="flgMenu ? 'open' : ''">
                <div class="content">
                    <ul class="menu">
                        <li @click="openAbout">
                            <span class="fullText">Ab
                                <span class="contentAbout" :class="flgAbout ? 'open' : ''">
                                    <p>Hello, I'm Tom Tran.<br> I have over 5 years of experience working with Angular, Vue, and web3, as well as a strong skill set in animation JavaScript. My proficiency in creating visually stunning and intuitive user interfaces, combined with my knowledge of blockchain technology makes me a valuable asset to any project seeking innovative solutions.</p>
                                </span>
                            ut</span>
                        </li>
                        <li>Work Experience</li>
                        <li @click="openProject">
                            <span class="fullText">Pr
                                <span class="oProjects" :class="flgProject ? 'open' : ''"></span>
                            jects
                            </span>
                            <div class="listProject" :class="flgProject ? 'open' : ''">
                                <a target="_blank" :href="item.url" class="item" v-for="item in projects" :key="item.id">
                                    <img :src="require('../assets/' + item.img)" alt="">
                                    <h2>{{ item.name }}</h2>
                                </a>
                            </div>
                        </li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                projects: [
                    {
                        name: 'Swish',
                        img: 'swish.jpg',
                        url: 'https://www.swish.fun/',
                    },
                    {
                        name: 'Momoka',
                        img: 'momoka.jpg',
                        url: 'https://momoka-hoikuen.jp/',
                    },
                    {
                        name: 'Ohbara',
                        img: 'ohbara.jpg',
                        url: 'https://momoka-hoikuen.jp/ohbara/blog/',
                    },
                    {
                        name: 'Whydah',
                        img: 'whydah.jpg',
                        url: 'https://www.whydah.xyz/',
                    },
                    {
                        name: 'Krabot',
                        img: 'krabot.jpg',
                        url: 'hhttps://krabots.io/',
                    },
                    {
                        name: 'Indetail',
                        img: 'indetail.jpg',
                        url: 'https://www.indetail.co.jp/',
                    },
                    {
                        name: 'Akasakazouen',
                        img: 'akasaka.jpg',
                        url: 'https://akasakazouen.com/',
                    },
                    {
                        name: 'Oku',
                        img: 'oku.jpg',
                        url: 'https://oku-k.co.jp/',
                    },
                    {
                        name: 'Idamura',
                        img: 'idamura.jpg',
                        url: 'https://idamura.xyz/',
                    },
                    {
                        name: 'Ortho',
                        img: 'ortho.jpg',
                        url: 'https://ortho.fashion/',
                    },
                    {
                        name: 'Defi Summer',
                        img: 'defi.jpg',
                        url: 'https://summer.kaidex.io/',
                    },
                ],
                flgMenu: false,
                flgAbout: false,
                flgProject: false,
            }
        },
        methods:{
            mouseMove(event){
                var circle = document.getElementById('circleMove')
                var circleEye = document.querySelector('.eye')
                var x = event.clientX * 100 / window.innerWidth + '%';
                var y = event.clientY * 100 / window.innerHeight + '%';
                // circle.style.right = y + "px";
                circle.style.transform = 'translateX(-'+x+')';
                circleEye.style.left = x;
                circleEye.style.top = y;
                circleEye.style.transform = 'translate('+x+', '+y+')';
            },
            toggleMenu(){
                this.flgMenu = !this.flgMenu;
            },
            openAbout(){
                this.flgAbout = !this.flgAbout;
                this.flgProject = false;
            },
            openProject(){
                this.flgProject = !this.flgProject;
                this.flgAbout = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
    // font-family: 'Quicksand', sans-serif;
    $color: #03C988;
    @keyframes eye {
        0%{
            height: 20px;
            border-radius: 10px;
        }
        50%{
            height: 170px;
            border-radius: 100px;
        }
        100%{
            height: 20px;
            border-radius: 10px;
        }
    }
    .fullPage{
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .banner{
            position: relative;
            h2{
                font-size: 15rem;
                color: #fff;
                font-weight: bold;
                font-family: 'Quicksand', sans-serif;
                white-space: nowrap;
                .circle{
                    background: $color;
                    width: 46%;
                    display: inline-block;
                    height: 167px;
                    border-radius: 100px;
                    position: relative;
                    overflow: hidden;
                    margin-left: -60px;
                    span{
                        display: inline-block;
                        transition: all .2s ease;
                        position: absolute;
                        height: 170px;
                        width: 170px;
                        background: #fff;
                        border-radius: 50%;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        // position: relative;
                        overflow: hidden;
                        // animation: eye 2s ease infinite;
                        // animation-delay: 4s;
                        .eye{
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background: #000;
                        }
                    }
                }
            }
            .marquee{
                background: #fff;
                padding-top: 15px;
                padding-bottom: 15px;
                border-radius: 100px;
                font-family: 'Exo 2', sans-serif;
                font-size: 1.9em;
                font-weight: bold;
                letter-spacing: 2px;
                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                span{
                    text-transform: uppercase;
                    display: inline-block;
                    &:not(:last-child)::after{
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: #212529;
                        border-radius: 50%;
                        display: inline-block;
                        margin: 0 20px 8px;
                    }
                }
            }
        }
        .sectionMenu{
            .iconMenu {
                position: fixed;
                z-index: 999;
                right: 100px;
                top: 50px;
                width: 80px;
                cursor: pointer;
                padding: 0;
                span {
                    display: block;
                    width: 100%;
                    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
                    border-radius: 3px;
                    height: 8px;
                    background: #fff;
                    transition: all .3s;
                    position: relative;
                }
                &.active{
                    span{
                        background: #13005A;
                    }
                }
            }


            span + span {
                margin-top: 14px;
            }

            .active span:nth-child(1) {
                animation: ease .7s top forwards;
            }

            .not-active span:nth-child(1) {
                animation: ease .7s top-2 forwards;
            }

            .active span:nth-child(2) {
                animation: ease .7s scaled forwards;
            }

            .not-active span:nth-child(2) {
                animation: ease .7s scaled-2 forwards;
            }

            .active span:nth-child(3) {
                animation: ease .7s bottom forwards;
            }

            .not-active span:nth-child(3) {
                animation: ease .7s bottom-2 forwards;
            }

            @keyframes top {
                0% {
                    top: 0;
                    transform: rotate(0);
                }
                50% {
                    top: 22px;
                    transform: rotate(0);
                }
                100% {
                    top: 22px;
                    transform: rotate(45deg);
                }
            }

            @keyframes top-2 {
                0% {
                    top: 22px;
                    transform: rotate(45deg);
                }
                50% {
                    top: 22px;
                    transform: rotate(0deg);
                }
                100% {
                    top: 0;
                    transform: rotate(0deg);
                }
            }

            @keyframes bottom {
                0% {
                    bottom: 0;
                    transform: rotate(0);
                }
                50% {
                    bottom: 22px;
                    transform: rotate(0);
                }
                100% {
                    bottom: 22px;
                    transform: rotate(135deg);
                }
            }

            @keyframes bottom-2 {
                0% {
                    bottom: 22px;
                    transform: rotate(135deg);
                }
                50% {
                    bottom: 22px;
                    transform: rotate(0);
                }
                100% {
                    bottom: 0;
                    transform: rotate(0);
                }
            }

            @keyframes scaled {
                50% {
                    transform: scale(0);
                }
                100% {
                    transform: scale(0);
                }
            }

            @keyframes scaled-2 {
                0% {
                    transform: scale(0);
                }
                50% {
                    transform: scale(0);
                }
                100% {
                    transform: scale(1);
                }
            }
            .boxContent{
                position: fixed;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                right: 100px;
                top: 50px;
                background: #fff;
                transition: all 1s ease;
                opacity: 0;
                visibility: hidden;
                overflow: hidden;
                &.open{
                    opacity: 1;
                    visibility: visible;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100vh;
                    border-radius: 0;
                }
                .content{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100vh;
                    width: 100%;
                    .menu{
                        text-align: center;
                        width: 100%;
                        li{
                            width: 100%;
                            text-transform: uppercase;
                            font-size: 4rem;
                            color: #13005A;
                            font-weight: bold;
                            font-family: "Quicksand", sans-serif;
                            white-space: nowrap;
                            transition: all .5s ease;
                            cursor: pointer;
                            padding: 10px 0;
                            white-space: nowrap;
                            position: relative;
                            &:hover{
                                background: $color;
                            }
                            span.fullText{
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                span.contentAbout{
                                    width: 48.5px;
                                    height: 50px;
                                    transition: all .5s ease;
                                    background: #13005A;
                                    border-radius: 50%;
                                    display: inline-block;
                                    // margin: 0 -15px;
                                    aspect-ratio: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    overflow: hidden;
                                    p{
                                        font-size: 14px;
                                        line-height: 18px;
                                        color: #fff;
                                        font-weight: normal;
                                        white-space: break-spaces;
                                        width: 70%;
                                        margin: 0;
                                        text-transform: initial;
                                        opacity: 0;
                                        transition: all .5s ease;
                                        font-family: 'Source Code Pro', monospace;
                                    }
                                    @media (min-width: 992px){
                                        &:hover{
                                            width: 80px;
                                            height: 80px;
                                        }
                                    }
                                    &.open{
                                        width: 350px;
                                        height: 350px;
                                        p{
                                            opacity: 1;
                                        }
                                    }
                                }
                                span.oProjects{
                                    width: 48.5px;
                                    height: 50px;
                                    transition: all .5s ease;
                                    background: #13005A;
                                    border-radius: 50%;
                                    display: inline-block;
                                    // margin: 0 -15px;
                                    aspect-ratio: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    overflow: hidden;
                                    @media (min-width: 993px){
                                        &:hover:not(.open){
                                            width: 80px;
                                            height: 80px;
                                        }
                                    }
                                    &.open{
                                        width: 65%;
                                        border-radius: 50px;
                                    }
                                }
                            }
                            .listProject{
                                display: flex;
                                flex-wrap: nowrap;
                                width: 80%;
                                margin: auto;
                                gap: 10px;
                                .item{
                                    width: 100%;
                                    transition: all .5s ease;
                                    height: 0;
                                    border-radius: 50px;
                                    overflow: hidden;
                                    border: 0px solid #13005A;
                                    opacity: 0;
                                    position: relative;
                                    >img{
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        object-position: center;
                                        transition: all .5s ease;

                                    }
                                }
                                &.open{
                                    .item{
                                        height: 200px;
                                        opacity: 1;
                                        border: 2px solid #13005A;
                                        &:hover{
                                            width: 600%;
                                            border-radius: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>